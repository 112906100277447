import React from 'react';
import { Link } from 'react-router-dom';

export const Home = () => {
    return (
        <>
            <section className="bg-white dark:bg-gray-900">
                <div className="grid max-w-screen-xl px-2 py-32 mx-auto lg:gap-8 xl:gap-0 xl:py-34 xl:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-7">
                        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Hi, my name is Boluwatife</h1>
                        <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                            Welcome to my portfolio website,  I am a Software Engineer.</p>
                        <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-gray-800 rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                            Contact Me
                            <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </Link>
                        <a href="https://gitconnected.com/boluajileye/resume" target='_blank' className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                            My Resume
                        </a>
                    </div>
                    <div className="sm:mt-3 lg:col-span-4 lg:flex pt-2">
                        <img src="https://media3.giphy.com/media/SWoSkN6DxTszqIKEqv/giphy.gif?cid=ecf05e477of0nx02eaa6dwq7z82ip2m75c4ute7qa1fvpnzk&rid=giphy.gif&ct=g" alt="portfolio" />
                    </div>
                </div>
            </section>
        </>
    )
}
